import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        

        <a href="https://link.awadao.org/awa_gate" 
          target="_blank" 
          rel="noopener noreferrer"
          class="mt-40"
          >
          <button class="awa-button">Enter </button>
        </a>
        
      </header>
    </div>
  );
}

export default App;
